.profilemenu {
    border-right: 2px solid black;
    flex: wrap;
    height:100vh;
    position: absolute;

    width: 17%;
    display: flex;
    justify-content: center;
    margin: auto;
    /* background-color: rgb(250, 250, 250); */
}

.submain>p:hover {
    box-shadow: inset 100px 0 0 0 #54b3d6;
    color: white;

}
.cententDiv{
    margin-left: 12%;
}
@media(max-width:768px){
.profilemenu{
    display: none;
}
.cententDiv {
        margin-left:0%;
    }

}