* {
    margin: 0;
    padding: 0;
}





.mainproducts {

    width: 100%;
    height: 100%;
}
.address{
    width: 75%;
    margin-left: 3%;
}
.productCarts {
    box-sizing: border-box;


    width: 35vw;


    margin: auto;



    border: 2px solid #000000
}

.Productmenu {
    flex: wrap;
    position: absolute;

    width: 15%;
    display: flex;
    justify-content: center;
    margin: auto;
    background-color: rgb(250, 250, 250);
}

.contentDivss{
    border-left:2px solid #000000;
    min-height: 100vh;
    margin-left: 17%;
}
   



.submain>p:hover {
    box-shadow: inset 100px 0 0 0 #54b3d6;
    color: white;

}

/* .cardContent {
    margin-left: 15%;
    border-left: 2px solid black;
   
} */

.responsive {
    width: 100%;
    /* height: 50%; */
}

.cartBord {
   
    width: 50%;
   margin-left: 3%;
    border: 3px solid black;
}
.priceCard{
    margin-left:5% ;
    margin-top: 1.5%;
}
.hide {
    display: none;
}

.myDIV:hover+.hide {
    display: block;
    color: rgb(0, 0, 255);
}

@media(max-width:768px) {
    .Productmenu {

        display: none;



    }


    .hamburger {
        display: fixed;
        padding-top: 10px;
        margin-left: 10px;
        z-index: 10;
    }
.contentDivs {
    
    /* border-left: 2px solid #000000; */
    margin-left: 0%;
}
    .cardContent {
        margin-left: 0%;
    }
        .address {
            width: 100%;
            margin-left: 0%;
        }
.cartBord {

    width: 98%;
    margin-left: 0%;
    /* height: 80%; */
    border: 3px solid black;
    /* border-left: 2px solid black; */
}

    .productCarts {
        box-sizing: border-box;


        width: 93vw;

        margin: auto;



        border: 3px solid #000000
    }
        .priceCard {
            width: 100%;
            margin-left: 0%;
            margin-top: 2%;
        }
}