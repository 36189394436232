* {
    margin: 0;
    padding: 0;
}





.mainproducts {

    width: 100%;
    height: 100%;
}



.productCarts {
    box-sizing: border-box;


    /* width: 35vw; */


    margin: auto;



    /* border: 2px solid #000000 */
}

.Productmenu {
    flex: wrap;
    position: absolute;

    width: 18%;
    display: flex;
    justify-content: center;
    margin: auto;
    background-color: rgb(250, 250, 250);
}

.contentDivs {
    min-height: 100vh;
    border-left: 2px solid #000000;
    margin-left: 19%;
}




.submain>p:hover {
    box-shadow: inset 100px 0 0 0 #54b3d6;
    color: white;

}

.cardContent {
    margin-left: 21%;
    border-left: 2px solid black;

}

.responsive {
    width: 100%;
    /* height: 100%; */
}

.cartBords {

    width: 80%;
    margin-left: 3%;
    border: 2px solid rgb(8, 13, 23);
    /* border-left: 2px solid black; */
}



@media(max-width:768px) {
    .Productmenu {

        display: none;



    }


    .hamburger {
        display: fixed;
        padding-top: 10px;
        margin-left: 10px;
        z-index: 10;
    }

    .contentDivs {
        /* border-left: 2px solid #000000; */
        margin-left: 0%;
    }

    .cardContent {
        margin-left: 0%;
    }



    .cartBords {

        width: 99%;
        margin-left: 0%;
        /* height: 80%; */
        border: 3px solid black;
        border-left: 2px solid black;
    }

    .productOrder {
        box-sizing: border-box;


        
    }


}