* {
    margin: 0;
    padding: 0;
}



.product-main {


    width: 100vw;

    background-image: url('../../AllImage/brooke-cagle.png');
    /* background-image:inherit; */
    background-repeat: no-repeat;

    background-position: 78%;

    height: 45rem;
    /* background-position: center; */
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;


}

.mainproducts {
margin-top:0px ;
}

.productCard {
    box-sizing: border-box;
    /* background-color:red ; */
    /* width: 100vw; */
    /* width: 75%; */
    margin: auto;
    /* border: 2px solid #000000 */
}

.Productmenus {
    /* border-right: 2px solid black; */
    flex: wrap;
    min-height:50vh;
    position: absolute;

    width: 17%;
    display: flex;
    justify-content: center;
    margin: auto;
    /* background-color: rgb(250, 250, 250); */
}

.submain>p:hover {
    box-shadow: inset 100px 0 0 0 #54b3d6;
    color: white;
    transition: .2s;
    transform: 1px;

}

.content {
    margin-left: 19%;
    /* border-left: 1px solid black; */
    /* height: 100vh; */
    /* overflow:scroll; */
}






@media(max-width:768px) {
    .Productmenus {

        display: none;



    }

    .hamburger {
        display: fixed;
        padding-top: 10px;
        margin-left: 10px;
        z-index: 10;
    }

    .content {
        margin-left: 1%;
       
    }


    .productCard {
        box-sizing: border-box;


        width: 95vw;

        margin: auto;



        border: 3px solid #000000
    }
}