.menu{
    border-right: 2px solid black;
            flex: wrap;
            min-height:100vh;
            position:absolute;
           
            width: 18%;
            display: flex;
            justify-content: center;
            margin:auto;
            /* background-color:rgb(250, 250, 250); */
}
.dashDiv{
    
    margin-left: 18%;
}

.submain>p:hover{
    box-shadow: inset 100px 0 0 0 #54b3d6;
        color: white;
        transition: 1s;

}
 .dashboardMenu{
margin-left: 2%;


 }

 @media(max-width:768px){

.menu {
       
        display: none;
        

       
    }
          .hamburger {
              display: fixed;
              padding-top: 10px;
              margin-left: 10px;
              z-index:10;
          }



    .dashboardMenu {
            margin-left: 0%;
    
 }
.dashDiv {
    margin-left:0%;
}

 }