.mainbox{
    margin-left: 17%;
    border-left:2px solid black;
    min-height:100vh;
}
@media(max-width:768px){
.mainbox {
        margin-left: 0%;
       
        /* min-height: 100vh; */
    }
}