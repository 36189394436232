.main-container{
    /* position: absolute; */
    /* position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; */
    /* margin-top:15rem; */
        /* width: 100vw; */
        
        
        bottom:0 ;
        /* margin-top:10%;; */
    
        background: #F2E9E4;


           
}
