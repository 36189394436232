* {
    margin: 0;
    padding: 0;
}





/* .mainproducts {

    width: 100%;
    height: 100%;
} */



.productCarts {
    box-sizing: border-box;


    /* width: 35vw; */


    margin: auto;



    /* border: 2px solid #000000 */
}

.Productmenu {
    flex: wrap;
    position: absolute;

    width: 15%;
    /* border-right: 2px solid #000000; */
    min-height:100vh;
    display: flex;
    justify-content: center;
    margin: auto;
    /* background-color: rgb(250, 250, 250); */
}

.contentDivss {
    min-height: 100vh;
   
    margin-left: 17%;
}




.submain>p:hover {
    box-shadow: inset 100px 0 0 0 #54b3d6;
    color: white;

}


.mainBords {

    width: 60%;
    margin-left: 5%;
    border: 2px solid black; 
}
.allchilddiv{
    width: 22vw;
    border:2px solid black;
    margin-left: 2rem;
}
.head{
    background-color: #87a0a9;
}


@media(max-width:768px) {
    .Productmenu {

        display: none;



    }


    .hamburger {
        display: fixed;
        padding-top: 10px;
        margin-left: 10px;
        z-index: 10;
    }

    .contentDivss {
        /* border-left: 2px solid #000000; */
        margin-left: 0%;
    }

    .cardContent {
        margin-left: 0%;
    }



    .mainBords {

        width: 99%;
        margin-left: 0%;
        /* height: 80%; */
        border: 1px solid black;
        border-left: 2px solid black;
    }

    .productOrder {
        box-sizing: border-box;


        /* width: 93vw;

        margin: auto; */



        /* border: 3px solid #000000 */
    }
.allchilddiv {
    display: flex;
    justify-content: center;
    width: 98%;
    margin-left: 1%;
    border: 2px solid black;
}

}