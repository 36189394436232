.main {
  position: relative;
  width: 100%;
  height: 4rem;
  left: 0px;
  top: 0px;

  background: #22223b;
}
.mainlogo {
  position: absolute;
  left: 2%;
  right: 0%;
  top: 15%;
  bottom: 0%;

  /* background: url('../AllImage/reinventlogo.png'); */
}
.menu-item {
  display: flex;
  float: right;
  position: absolute;
  /* margin-left:5rem; */
  right: 0%;
  top: 10%;
  /* bottom: 2.88%; */

  /* background: #F2E9E4; */
}
.wishlist {
  position: absolute;
  /* width: 57px;
        height: 18px;
        left: 1220px;
        top: 48px; */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */

  color: #f2e9e4;
}
