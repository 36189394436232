* {
    margin: 0;
    padding: 0;
}



.product-main {


    width: 100vw;

    background-image: url('../../AllImage/brooke-cagle.png');
    /* background-image:inherit; */
    background-repeat: no-repeat;

    background-position: 78%;

    height: 45rem;
    /* background-position: center; */
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;


}



.productCard {
    box-sizing: border-box;
    /* background-color:red ; */
    width: 75vw;
   
    /* width: 75%; */
    margin: auto;
    border: 2px solid #000000
}

.Productmenu {
    /* border-right: 2px solid black; */
    flex: wrap;
    /* height:100vh; */
    position: absolute;

    width: 17%;
    display: flex;
    justify-content: center;
    margin: auto;
    background-color: rgb(250, 250, 250);
}

.submain>p:hover {
    box-shadow: inset 100px 0 0 0 #54b3d6;
    color: white;

}

.cardContent {
    margin-left: 17%;
    border-left: 2px solid black;
    /* height: 100vh; */
    /* overflow:scroll; */
}

.responsive {
    width: 100%;
    height: 100%;
}




@media(max-width:768px) {
    .Productmenu {

        display: none;



    }

    .hamburger {
        display: fixed;
        padding-top: 10px;
        margin-left: 10px;
        z-index: 10;
    }

    .cardContent {
        margin-left: 0%;
    }


    .productCard {
        box-sizing: border-box;


        width: 95vw;

        margin: auto;



        border: 3px solid #000000
    }
}