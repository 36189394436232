



.mainproducts {

    width: 100%;
    height: 100%;
}






.Productmenu {
    flex: wrap;
    position: absolute;
min-height: 100vh;
    width: 15%;
    display: flex;
    justify-content: center;
    margin: auto;
    background-color: rgb(250, 250, 250);
}

.contentDivs {
    border-left: 2px solid #000000;
    min-height: 100vh;
    margin-left: 17%;
}




.submain>p:hover {
    box-shadow: inset 100px 0 0 0 #54b3d6;
    color: white;

}

.cont{
    margin-left: 9%;
}

.contWidth {
    width: 75%;
    background-color: #C9ADA7;
}


@media(max-width:768px) {
    .Productmenu {

        display: none;



    }


    .hamburger {
        display: fixed;
        padding-top: 10px;
        margin-left: 10px;
        z-index: 10;
    }

    .contentDivs {
        /* border-left: 2px solid #000000; */
        margin-left: 0%;
    }

    .cardContent {
        margin-left: 0%;
    }

.cont {
    margin-left: 2%;
}
.contWidth{
    width: 98%;
    background-color:#C9ADA7 ;
}

  
   

}